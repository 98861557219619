import { useDispatch, useSelector } from 'react-redux';
import ERC721 from '../ABI/erc721.json'
import ERC1155 from '../ABI/erc1155.json'
import DETH from '../ABI/erc20.json'
import Market from '../ABI/trade.json'
import config from '../config/config'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import Web3 from 'web3';
import { NumberChange, isEmpty } from './common';
import randomInteger from 'random-int';
import { GetNftCookieToken, NftbalanceUpdate } from './axios/nft.axios';
import StakeAbi from '../ABI/stakeabi.json'
import { ChangeNetwork, ValidChainCheck, connectWallet } from '../hooks/useWallet';
import { toast } from 'react-toastify';
import { GetUserCookieToken, userRegister } from './axios/user.axios';

// import Web3Utils from 'web3-utils'



export default function useContractProviderHook() {

    const { accountAddress, web3, web3p, coinBalance, cartRefresh, currentNetwork } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees, buyerFees,collectiodeloyFees } = useSelector(state => state.LoginReducer.ServiceFees);
    const { payload, token, CartList } = useSelector(
        (state) => state.LoginReducer.User
      );

    const dispatch = useDispatch();

    var web3s = new Web3(currentNetwork?.RPC_URL?currentNetwork?.RPC_URL:config?.RPC_URL)
    console.log("web3s@123", web3s,currentNetwork,currentNetwork?.RPC_URL?currentNetwork?.RPC_URL:config?.RPC_URL);

    const Contract_Base_Validation = () => {
        if (!web3) return 'Connect Your Wallet'
        if (!accountAddress) return 'Connect Your Wallet'
        if (!coinBalance) return "You Don't have Enough Balance"
        else return ''
    }

    const contrat_connection = async (...data) => {
        const CHAIN_ID = currentNetwork.CHAIN_ID;
        let chainid = await web3.eth.getChainId();
        
        console.log('')
        if (web3) {
            var contract_value = await new web3.eth.Contract(
                ...data
            );
            return contract_value;
        }
    }

    const GetApproveStatus = async (data, Addr) => {
        // console.log("datadata",data,Addr,accountAddress);
        // debugger
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .isApprovedForAll(accountAddress, currentNetwork?.TradeContract)
                    .call()
            return contract_Method_Hash

        }
        catch (e) {
            console.log("isApprovedForAll Error",e);
            return 'error'
        }
    }
    const SetApproveStatus = async (data, Addr) => {
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            var contractobj = await
                    ConnectContract
                        .methods
                        .setApprovalForAll(currentNetwork?.TradeContract, true)

            // let encoded = await ConnectContract.methods.setApprovalForAll(currentNetwork.TradeContract, true).encodeABI();
            // var gasfeecalculated = await GetGasFees(encoded,Addr);
            
            var gasprice = await web3.eth.getGasPrice();
            var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .setApprovalForAll(currentNetwork?.TradeContract, true)
                    .send({ 
                        from: accountAddress, 
                        gasLimit: parseInt(gas_estimate), 
                        gasPrice: gasprice,
                    })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            console.log("enna varra", need_data);
            return need_data;

        }
        catch (e) {
            console.log("setapproveerr", e)
            return false
        }
    }

const get_receipt = async (HashValue) => {
    let receipt;
    do {
        console.log('HashValueHashValue',HashValue)
        receipt = await web3.eth.getTransactionReceipt(HashValue);
        console.log('receiptreceipt',receipt)
    } while (!receipt)
    if (receipt?.status) {
        return receipt
    }

}
    const minting_721_1155 = async (ContractType,...data) => {
        try {
            console.log("mintingminting",...data);

            const ConnectContract = await contrat_connection(Market, currentNetwork?.TradeContract)
            console.log('gas_estimategas_estimate111',ConnectContract)
            var contractobj = await
            ConnectContract
                .methods
                .minting(...data)
                var gasprice = await web3.eth.getGasPrice();
                var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            
            console.log('gas_estimategas_estimate',)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .minting(...data)
                    .send({ 
                        from: accountAddress,
                        gasLimit: parseInt(gas_estimate), 
                        gasPrice: gasprice,
                    })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
                    console.log('contract_Method_Hash',contract_Method_Hash)
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            if(!isEmpty(receipt)){
                // console.log("sdfsdgsfg",ContractType == "721",receipt.logs[4],receipt.logs[0].data);
                console.log('receiptreceipt',receipt)

            var Tokenid =
            ContractType == "721"?
            web3?.utils?.hexToNumber(receipt.logs[0].topics[3])
            :
            web3?.utils?.hexToNumber(receipt.logs[4].topics[2])
            
            var Certificateid = web3?.utils?.hexToNumber(receipt.logs[1].topics[3])

            // console.log("TokenCOuntsTokenCOunts",Tokenid);
            if (Tokenid) {
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                    tokenCounts: Tokenid,
                    Certificateid:Certificateid
                }
                return need_data
            }
        }
        }
        catch (e) {
            console.log("minting_721_1155 err",e);
            return false
        }


    }
    const approve_721_1155 = async (token_address, ...data) => {

        try {
            const ConnectContract = await contrat_connection(DETH, token_address)
            var contractobj = await
            ConnectContract
                .methods
                .approve(...data)
            // console.log("sdgjhfsjkghfs",...data,token_address);
            // let encoded = await ConnectContract.methods
            // .approve(...data).encodeABI();

            // var gasfeecalculated = await GetGasFees(encoded,currentNetwork.TradeContract);
            // var gasprice = await web3.eth.getGasPrice();
            // var gas_estimate = await contractobj.estimateGas({ from: accountAddress })

            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .approve(...data)
                    .send({ 
                        from: accountAddress,
                        // gasLimit: parseInt(gas_estimate), 
                        // gasPrice: gasprice,
                     })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            console.log("hassssh", contract_Method_Hash);
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.error("ERRRRR", e)
            return false
        }
    }
    const Token_Balance_Calculation = async (token_Address, data) => {
        try {
            const ConnectContract = await contrat_connection(DETH, token_Address)
            var bidAMt = await ConnectContract.methods.balanceOf(data).call();
            var tokenbal = Number(web3?.utils?.fromWei(String(bidAMt)))

            if (Math.abs(tokenbal) < 1.0) {
                var e = parseInt(tokenbal.toString().split('e-')[1]);
                if (e) {
                    tokenbal *= Math.pow(10, e - 1);
                    tokenbal = '0.' + (new Array(e)).join('0') + tokenbal.toString().substring(2);
                }
            } else {
                var e = parseInt(tokenbal.toString().split('+')[1]);
                if (e > 20) {
                    e -= 20;
                    tokenbal /= Math.pow(10, e);
                    tokenbal += (new Array(e + 1)).join('0');
                }
            }
            console.log("WEWEW", tokenbal, typeof (tokenbal))

            return Number(tokenbal).toFixed(10)
        }
        catch (e) {
console.log("Token_Balance_Calculation err",e);
            return 0
        }
    }
    var buy_bid_price_calculation = (val, decimal) => {
        var toMid = Number(val) * 1000000

        var serfee = (toMid / 100000000) * (web3p?.utils?.fromWei(String(buyerFees ? buyerFees : 1)) * 1000000)
        var totfee = serfee + toMid
        var tot2cont = web3?.utils?.toWei(String(Number(totfee / 1000000)).length > 18 ? String(Number(totfee / 1000000).toFixed(18)) : String(Number(totfee / 1000000)))
        var dec = decimal == 18 ? 18 : 18 - (decimal);
        var aprrove = ((tot2cont) / 10 ** dec)
        return (aprrove)
    }
    const cancel_order_721_1155 = async (data) => {
        try {
            var ConnectContract = await contrat_connection(Market, currentNetwork?.TradeContract)
            var contractobj = await
            ConnectContract
                .methods
                .cancelOrder(data)
        var gasprice = await web3.eth.getGasPrice();

        var gas_estimate = await contractobj.estimateGas({ from: accountAddress })

            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .cancelOrder(data)
                    .send({
                         from: accountAddress,
                         gasLimit: parseInt(gas_estimate), 
                         gasPrice: gasprice,
                     
                    
                    })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (e) {
            console.log("cancel_order_721_1155",e);
            return false
        }

    }
    var price_calculation = (data, roy) => {
        try {

            var price = web3?.utils?.toWei(data);
            // var weii = price*1e6
            // var ser_per = (weii/100000000)*((Wallet_Details.sellerfee/currentNetwork.decimalvalues)*1000000)
            // var roy_per = (weii/100000000)*((roy)*1000000)
            // var mulWei = ((weii) - (ser_per+roy_per));
            // var getVal = Number(mulWei)/1e6;
            //console..log("you will get lower",price,weii,ser_per,roy_per,getVal);
            var service_from_val = ((price * (sellerFees)) / 1e20)
            var royal_from_val = ((price * (roy * 1e18)) / 1e20)
            var my_val = 0, my_val_royal = 0, getVal = 0;
            if (String(service_from_val).includes('.') && String(service_from_val).split('.').pop().length > 18)
                my_val = service_from_val.toFixed(18)
            else
                my_val = service_from_val

            if (String(royal_from_val).includes('.') && String(royal_from_val).split('.').pop().length > 18)
                my_val_royal = royal_from_val.toFixed(18)
            else
                my_val_royal = royal_from_val
            var whole_val = (((price)) - my_val) / 1e18
            if (String(whole_val).includes('.') && String(whole_val).split('.').pop().length > 18)
                getVal = whole_val.toFixed(18)
            else
                getVal = whole_val
            //console(data, getVal, sellerFees, my_val, my_val_royal)




            if (Math.abs(getVal) < 1.0) {
                var e = parseInt(getVal.toString().split('e-')[1]);
                if (e) {
                    getVal *= Math.pow(10, e - 1);
                    getVal = '0.' + (new Array(e)).join('0') + getVal.toString().substring(2);
                }
            } else {
                var e = parseInt(getVal.toString().split('+')[1]);
                if (e > 20) {
                    e -= 20;
                    getVal /= Math.pow(10, e);
                    getVal += (new Array(e + 1)).join('0');
                }
            }

            return Number(getVal).toFixed(10)

        }
        catch (e) {
            return false
        }
    }
    const place_order_721_1155 = async (...data) => {
        try {
            var ConnectContract = await contrat_connection(Market, currentNetwork?.TradeContract)
            let encoded = await ConnectContract.methods
            .orderPlace(...data).encodeABI();
            var gasfeecalculated = await GetGasFees(encoded,currentNetwork?.TradeContract);

            var contract_Method_Hash = await
                ConnectContract.methods
                    .orderPlace(...data)
                    .send({ from: accountAddress,
                        gasLimit: parseInt(gasfeecalculated?.gasdata * 1.5, 10), 
                        gasPrice: gasfeecalculated?.gasPrice, })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("place_order_721_1155place_order_721_1155", e)
            return false
        }

    }
    const buy_721_1155 = async (ContractAddress,Send, CoinName, ...data) => {
        try {
            const ConnectContract = await contrat_connection(Market, currentNetwork?.TradeContract)
     


            if (CoinName == currentNetwork.COIN_NAME) {

                console.log('dattttaaaaa',...data)
                var contractobj = await
                ConnectContract
                    .methods
                    .saleToken(...data)

                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress,value: Send }) 

                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleToken(...data)
                        .send({
                            from: accountAddress,
                            value: Send,
                            gasLimit: parseInt(gas_estimate), 
                            gasPrice: gasprice,
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            else {
                console.log("noncenonce",CoinName,...data);
    
                var contractobj = await
                ConnectContract
                    .methods
                    .saleWithToken(CoinName,...data)

                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress }) 

                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleWithToken(CoinName, ...data)
                        .send({ 
                            from: accountAddress,
                            gasLimit: parseInt(gas_estimate), 
                            gasPrice: gasprice,
                         })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
          
            var certificateid=GetCertificateId(receipt.logs,ContractAddress)

            var need_data ={
                            sellercertificateid:certificateid?.[0]?.fromCertificateId,
                            buyercertificateid:certificateid?.[0]?.toCertificateId,
                            status: receipt.status,
                            HashValue: receipt.transactionHash,
                            StakedNFTIndex: certificateid?.[0]?.StakedNFTIndex
            }

 
            return need_data
        }
        catch (e) {
            console.error("ERREEER", e)
            return false
        }

    }
    const allowance_721_1155 = async (token_Address, data) => {
        try {
            console.log("ddddddddddd",token_Address,data);
            const ConnectContract = await contrat_connection(DETH, token_Address)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .allowance(data, currentNetwork?.TradeContract)
                    .call()
                    console.log("contract_Method_Hash",contract_Method_Hash);

            return contract_Method_Hash
        }

        catch (e) {
            return false
        }

    }
    const accept_721_1155 = async (ContractAddress,...data) => {
        try {
            console.log("accept_721_1155",...data);
            if (web3 != null) {
                const ConnectContract = await contrat_connection(Market, currentNetwork?.TradeContract)
                var contractobj = await
                ConnectContract
                    .methods
                    .acceptBId( 
                       ...data
                        )

                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
               
                    var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .acceptBId(...data)
                        .send({ 
                            from: accountAddress,
                            gasLimit: parseInt(gas_estimate), 
                            gasPrice: gasprice,
                         })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                // console.log("Acceptsadasdf",receipt);
                var certificateid=GetCertificateId(receipt.logs,ContractAddress)

                var need_data ={
                                sellercertificateid:certificateid?.[0]?.fromCertificateId,
                                buyercertificateid:certificateid?.[0]?.toCertificateId,
                                status: receipt.status,
                                HashValue: receipt.transactionHash,
                                StakedNFTIndex: certificateid?.[0]?.StakedNFTIndex
                }

                return need_data

            }
        }
        catch (e) {
            console.log('accept_721_1155error',e)
            return false
        }

    }
    const burn_721_1155 = async (ContractAddress,ContractType,Quantity,Add,Id) => {
        // console.log("fertaea",ContractAddress,ContractType,Quantity,Add,Id);
        try {

                const ConnectContract = await contrat_connection((ContractType === "721" || ContractType === 721 )?  ERC721 : ERC1155,ContractAddress)
               if(ContractType == "721"|| ContractType == 721)
                {                
                    var contractobj = await
                    ConnectContract
                        .methods
                        .burnNFT(Id)
    
                        var gasprice = await web3.eth.getGasPrice();
                        var gas_estimate = await contractobj.estimateGas({ from: accountAddress }) 
                    
                    var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .burnNFT(Id)
                            .send({ 
                                
                             from: accountAddress,
                             gasLimit: parseInt(gas_estimate), 
                             gasPrice: gasprice,
                             })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })
                        }
                        else{
                            var contractobj = await
                            ConnectContract
                                .methods
                                .burnNFT(Id,Quantity)
            
                                var gasprice = await web3.eth.getGasPrice();
                                var gas_estimate = await contractobj.estimateGas({ from: accountAddress }) 
                             
                                var contract_Method_Hash = await
                            ConnectContract
                                .methods
                                .burnNFT(Id,Quantity)
                                .send({ 
                                    from: accountAddress ,
                                    gasLimit: parseInt(gas_estimate), 
                                    gasPrice: gasprice,
                                })
                                .on('transactionHash', (transactionHash) => {
                                    return transactionHash
                                })}
                    const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                          }
                    return need_data
                }
                catch (e) {
console.log("catch",e);
                    return false
                }

            }
    const GetOwner = async (data, Addr, Tokenaddr) => {
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .ownerOf(Tokenaddr)
                    .call()
            return contract_Method_Hash

        }
        catch (e) {
            console.log('errrorrrr', e)
            return 'error'
        }
    }

    const GetContractOwner = async (data, Addr) => {
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            var contractowner = await
                ConnectContract
                    .methods
                    .owner()
                    .call()
            return contractowner

        }
        catch (e) {
            console.log('errrorrrr', e, String(e))
            return 'error'
        }
    }

    const Current_NFT_Balance = async (ownerdet, data) => {
        try {
            var currbalance;
            if ((data.ContractType === "721" || data.ContractType === 721)) {
                const ConnectContract = await contrat_connection(ERC721, data.ContractAddress)
                currbalance = await ConnectContract.methods.ownerOf(ownerdet.NFTId).call();
                if ((String(currbalance).toLowerCase()) === (String(ownerdet.NFTOwner).toLowerCase())) { return '1'; }
                else {
                    let payload = {
                        NFTId: ownerdet?.NFTId,
                        NFTOwner: ownerdet?.NFTOwner,
                        NFTBalance: "0",
                        Currentowner: currbalance,
                        type: '721'
                    }
                    var response = await NftbalanceUpdate(payload);
                    return String(currbalance);

                }
            }
            else {
                const ConnectContract = await contrat_connection(ERC1155, data.ContractAddress)
                currbalance = await ConnectContract.methods.balanceOf(ownerdet.NFTOwner, ownerdet.NFTId).call();
                console.log('balanceeeeeee',currbalance,data.ContractAddress,ownerdet)
                let stakebalance = await ConnectContract.methods.balanceOf(currentNetwork.Stakecontract, ownerdet.NFTId).call();
                // console.log('stakebalancestakebalance',((currbalance !== null && currbalance !== undefined) && String(ownerdet.NFTBalance) != String(currbalance)),(NumberChange(stakebalance)!=NumberChange(ownerdet.StakedNFTQuantity)))
                if ((currbalance !== null && currbalance !== undefined) && String(ownerdet.NFTBalance) != String(currbalance)) {
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: Number(currbalance)+ (!isNaN(Number(ownerdet.StakedNFTQuantity)) ? Number(ownerdet.StakedNFTQuantity) : 0) ,
                        Currentowner: currbalance,
                        type: '1155',
                        StakedNFTQuantity: stakebalance,
                    }
                    let result = await NftbalanceUpdate(payload);
                    return String(currbalance);
                }
                else if(NumberChange(stakebalance)!=NumberChange(ownerdet.StakedNFTQuantity)){
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: Number(currbalance)+ (!isNaN(Number(ownerdet.StakedNFTQuantity)) ? Number(ownerdet.StakedNFTQuantity) : 0) ,
                        Currentowner: currbalance,
                        type: '1155',
                        StakedNFTQuantity: stakebalance,
                    }
                    let result = await NftbalanceUpdate(payload);
                }
                return String(currbalance);
            }
        }
        catch (e) {
            console.log('errorrrr,e', e)
            return 0
        }
    }

    const Transfer = async (data) => {

        try {
            const ConnectContract = await contrat_connection(data.ContractType == "1155" ? ERC1155 : ERC721, data.ContractType == "1155" ? data?.ContractAddress: currentNetwork.ERC721)
           
            var contract_Method_Hash
            if (data.ContractType == "1155" || data.ContractType == 1155) {
                var contractobj = await
                ConnectContract
                    .methods
                    .safeTransferFrom( 
                         data.nftOwner,
                        data.Address,
                        data.NFTId,
                        data.ContractType == "1155" ? data.amount : "0",
                        "0x0"
                        )

                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress }) 

                contract_Method_Hash = await ConnectContract.methods
                    .safeTransferFrom(
                        data.nftOwner,
                        data.Address,
                        data.NFTId,
                        data.ContractType == "1155" ? data.amount : "0",
                        "0x0"
                    )
                    .send({ 
                        from: data.nftOwner,
                        gasLimit: parseInt(gas_estimate), 
                        gasPrice: gasprice,
                    })
                    .on("transactionHash", (transactionHash) => {
                        return transactionHash;
                    });
            } else if (data.ContractType == "721" || data.ContractType == 721) {
                var contractobj = await
                ConnectContract
                    .methods
                    .safeTransferFrom( 
                        data.nftOwner,
                        data.Address,
                        data.NFTId
                        )

                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress }) 

                contract_Method_Hash = await ConnectContract.methods
                    .safeTransferFrom(
                        data.nftOwner,
                        data.Address,
                        data.NFTId
                    )
                    .send({
                         from: data.nftOwner,
                         gasLimit: parseInt(gas_estimate), 
                         gasPrice: gasprice,
                         })
                    .on("transactionHash", (transactionHash) => {
                        console.log("hasdh", transactionHash);
                        return transactionHash;
                    });
            }



            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data

        } catch (err) {
            console.log("err in mintin", err);
        }
    };

    const GetGasFees = async (encoded,contractaddress,value) => {
        try{
          var gasPrice = await web3.eth.getGasPrice();
          // console.log('skjfffffffssssss@124',chain,TradeAddress);
          var gasdata;
            if(value){
              gasdata = await web3.eth.estimateGas({
                from: accountAddress,
                to: contractaddress,
                value:value,
                data: encoded,
              });
            }
            else{
              gasdata = await web3.eth.estimateGas({
                from: accountAddress,
                to: contractaddress,
                data: encoded,
              });
            }
            
            console.log('hdgdgkdggd',gasPrice,gasdata)
            return({gasdata:gasdata,gasPrice:gasPrice});
        }
        catch(err){ 
          console.log('errorrr',err);
          return ({gasdata:282984,gasPrice:5500000000});
        }
    }
     const getServiceFees = async () => {
        // var rpcObj = new web3(currentNetwork.RPC_URL)   
        var rpcObj=web3
        var fees = {}
        if(rpcObj){
          try{
            var marketObj = new rpcObj.eth.Contract(
              Market,
              currentNetwork?.TradeContract
            );
            var servicefees = await marketObj.methods.getServiceFee().call()
            console.log("servicefees",servicefees);
            fees.buyerFees = servicefees[0]
            fees.sellerFees = servicefees[1]
            
            return fees;
          }
          catch(e){
            console("service fees catch blok running",e)
          }
        }
      }

const createcollection_1155=async(...data)=>{
try{
    const ConnectContract = await contrat_connection(Market, currentNetwork?.TradeContract)
    var contractobj = await
    ConnectContract
        .methods
        .createCollection(...data)

        var gasprice = await web3.eth.getGasPrice();
        var gas_estimate = await contractobj.estimateGas({ from: accountAddress,
             value:(web3p?.utils?.fromWei(String(collectiodeloyFees ? collectiodeloyFees : 1)))})
    var contract_Method_Hash = await
        ConnectContract
            .methods
            .createCollection(...data)
            .send({
                from: accountAddress,
                value:(web3p?.utils?.fromWei(String(collectiodeloyFees ? collectiodeloyFees : 1))),
                gasLimit: parseInt(gas_estimate), 
                gasPrice: gasprice,
            })
            .on('transactionHash', (transactionHash) => {
                return transactionHash
            })
            
            const receipt = await get_receipt(contract_Method_Hash?.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
                contractaddress:web3p.eth.abi.decodeParameter("address",receipt?.logs?.[0]?.topics?.[1])
            }
            return need_data
        }
        catch(err){
            console.log("errerr",err);
            return need_data
        }
}
const _signcall = async (NFTprice,WalletAddress) => {
    try{
    if (web3) {
        const passwords = require('secure-random-password');
        let TokenPrice = NFTprice
        let randomNum = randomInteger(10000000, 100000000);
        let password;
        try {
            password = passwords.randomPassword({ length: 10, characters: [passwords.lower, passwords.upper, passwords.digits] });
        }
        catch (err) {
            console.log('randomIntegerrandomInteger', err)
        }
        if (web3) {
            var web3RpcPro = new Web3(web3.providers);
            const to =WalletAddress
            const _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3.utils.toWei(String(TokenPrice));
            const _nonce = Date.now();
            let tot = _nonce + Number(randomNum);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            const signhash = await web3.eth.personal.sign(result, to);
            console.log('contcontlisttttinnn',signhash,tot,password,to,_amount);
            if (signhash) {
                return ({ signhash: signhash, tot: tot, password: password })
            }
        }
    }
}
catch(err){
    console.log("_signcall_err",err);
    return false
}
} 

var Collection_price_calculation = (val, decimal) => {
    var toMid = Number(val) * 1000000

    var serfee = (toMid / 100000000) * (web3p?.utils?.fromWei(String(collectiodeloyFees ? collectiodeloyFees : 1)) * 1000000)
    var totfee = serfee + toMid
    var tot2cont = web3?.utils?.toWei(String(Number(totfee / 1000000)).length > 18 ? String(Number(totfee / 1000000).toFixed(18)) : String(Number(totfee / 1000000)))
    var dec = decimal == 18 ? 18 : 18 - (decimal);
    var aprrove = ((tot2cont) / 10 ** dec)
    return (aprrove)
}

const getStackPools = async () => {
    try {
        var ConnectContract = await contrat_connection(StakeAbi,currentNetwork.Stakecontract)

        var getCount = await ConnectContract
            .methods
            .poolCount()
            .call()
            console.log('getCountgetCount',getCount,currentNetwork.Stakecontract)
        const getData = await Promise.all([...Array(Number(getCount))].map(async (val, i) => {
            console.log('poolStatuspoolStatus',i,)
            let poolStatus = await ConnectContract
                .methods
                .poolStatus(i + 1)
                .call()
                console.log('poolStatuspoolStatus22222',i,poolStatus,i+1)
            const obj = {
                poolId: i + 1,
                // lockPeriod: web3.utils.fromWei(poolStatus.lockPeriod),
                lockPeriod: poolStatus.lockPeriod,
                rewardToken: poolStatus.rewardToken,
                _enablestack: poolStatus._enablestack,

            }
            return obj
        }))

        return {
            status: true,
            data: getData
        }
    } catch (e) {
        console.log("erro ongetStackPools ", e);
        return { status: false }
    }
}

const AddStackpool = async ({rewardtoken,count}) => {
    try{
        var ConnectContract = await contrat_connection(StakeAbi,currentNetwork.Stakecontract)
        count = 15;
        let getCount = await ConnectContract
            .methods
            .poolCount()
            .call()
        let outputhash = [];
        for(let i=NumberChange(getCount)+1; i <= count; i++){
            console.log('iiiiiiiiiiiiiiiiii',i*365,i)
            console.log('webbbbbbbbb',web3.utils.toWei(String(i*365)))
            console.log('datttaaa',rewardtoken,i,web3.utils.toWei(String(i*365)),0,true)
            var ConnectContract = await contrat_connection(StakeAbi,currentNetwork.Stakecontract)
            var contractobj = await
                ConnectContract
                    .methods
                    .editPool(
                        rewardtoken,
                        web3.utils.toWei(String(i*365)),
                        0,
                        true
                    );
    
            var gasprice = await web3.eth.getGasPrice();
            var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            var hash = await ConnectContract
                .methods
                .editPool(
                    rewardtoken,
                    web3.utils.toWei(String(i*365)),
                    0,
                    true
                )
                .send({
                    from: accountAddress,
                    gas: web3.utils.toHex(gas_estimate),
                    gasPrice: web3.utils.toHex(gasprice),
                })
            console.log('hashhash',hash);
            outputhash.push(hash);
            // window.alert(i+" years")
        }
        return outputhash;
    }
    catch (e) {
        console.log("AddStackpool_error ", e);
        return [];
    }
}

const setApproveForStack = async (Addr) => {
    try {
        var ConnectContract = await contrat_connection(ERC1155, Addr)
        var contractobj = await
            ConnectContract
                .methods
                .setApprovalForAll(currentNetwork.Stakecontract, true)

        var gasprice = await web3.eth.getGasPrice();
        var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
        var contract_Method_Hash = await
            ConnectContract
                .methods
                .setApprovalForAll(currentNetwork.Stakecontract, true)
                .send({
                    from: accountAddress,
                    gas: web3.utils.toHex(gas_estimate),
                    gasPrice: web3.utils.toHex(gasprice),
                }).on('transactionHash', (transactionHash) => {
                    return transactionHash
                })
        const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

        var need_data = {
            status: receipt.status,
            HashValue: receipt.transactionHash,
        }
        return need_data;

    }
    catch (e) {
        console.log("ERR", e);
        return false
    }
}

const getStackApproveStatus = async (Addr) => {
    var ConnectContract = await contrat_connection(ERC1155, Addr)
    var contract_Method_Hash = await
        ConnectContract
            .methods
            .isApprovedForAll(accountAddress,currentNetwork.Stakecontract )
            .call()
    console.log("contract_Method_Hash", contract_Method_Hash);
    return contract_Method_Hash
}
const nftStakingAndWithdrawAndClaim = async (method,...data) => {
    try {
        console.log("...datasasasda", ...data);
        var ConnectContract = await contrat_connection(StakeAbi, currentNetwork.Stakecontract)


        let contractobj = await
            ConnectContract
                .methods[method](...data)

        let gasprice = await web3.eth.getGasPrice();
        let gas_estimate = await contractobj.estimateGas({ from: accountAddress })

        let contract_Method_Hash = await contractobj.send({
            from: accountAddress,
            gasLimit: parseInt(gas_estimate), 
            gasPrice: gasprice,
        })
            .on('transactionHash', (transactionHash) => {
                return transactionHash
            });

        const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
        // web3p.eth.abi.decodeParameter("address",receipt?.logs?.[0]?.topics?.[1])
        var need_data = {
            status: receipt.status,
            HashValue: receipt.transactionHash,
            stakedindexstatus:web3?.utils?.hexToNumber(receipt?.logs[1]?.data)
        }
        return need_data
    } catch (e) {
        console.log("err on nftStaking", e);
        return { status: false }
    }

}

const batchSale = async ( Send,...data ) => {
    try{
        const ConnectContract = await contrat_connection(
          Market,
          currentNetwork?.TradeContract
        );
        // console.log('dhgfjkdasgfkdf',Send,...data);
        let senddata = { from: accountAddress, };
        if(Send){
            senddata.value = Send
        }
        // console.log('datadata',...data)
        let contractobj = await ConnectContract.methods.batchSale(...data)
        // console.log('senddatasenddata',senddata)
        senddata.gasLimit = await contractobj.estimateGas(senddata)
        // console.log('senddatasenddata111',senddata)
        senddata.gasPrice = await web3.eth.getGasPrice();

        // let gasprice = await web3.eth.getGasPrice();
        // let gas_estimate = await contractobj.estimateGas({ from: accountAddress })
        console.log('senddatasenddata222',senddata)
        var contract_Method_Hash = await contractobj
            .send(senddata)
            .on("transactionHash", (transactionHash) => {
              return transactionHash;
            });
      
        const receipt = await get_receipt(
          contract_Method_Hash.transactionHash
            ? contract_Method_Hash.transactionHash
            : contract_Method_Hash
        );
      
        // console.log('reciepttt',receipt);

        // if(receipt.logs.length > 0){
        //     GetCertificateId(receipt.logs)
        // }
        var need_data = {
          status: receipt.status,
          HashValue: receipt.transactionHash,
          logs: receipt.logs,
        };
        return need_data;
        }
        catch(err){
          console.log("batchsale error",err)
          return false;
        }
}

const GetCertificateId = (logs, Contractarray) => {
    try{
        let outputarray = [];
        console.log("ContractarrayContractarray",Contractarray,logs)
        Contractarray = Array.isArray(Contractarray) ? Contractarray : (Contractarray ? [Contractarray] : undefined);
        if( Array.isArray(logs) && logs?.length > 0 && Contractarray?.length > 0 && Contractarray ){
            logs.map((log,ind)=>{
                let lastindex = outputarray?.length-1;
                // console.log('mappppppp',log?.topics?.[2],DecodeAddress(log?.topics?.[2]),HextoNumber(DecodeAddress(log?.topics?.[2])),web3?.utils?.isAddress(DecodeAddress(log?.topics?.[2])),(isEmpty(log?.data) && HextoNumber(log.topics[2])),ind)
                if(Contractarray?.includes(log?.address?.toLowerCase())){
                    // console.log('mapppppppifffff',log?.data,ind,HextoNumber(log?.data.slice(0,((log?.data?.length/2)+1))),typeof(HextoNumber(log?.data.slice(0,((log?.data?.length/2)+1)))),HextoNumber(log?.data.slice(0,((log?.data?.length/2)+1))))
                    if( log?.data && HextoNumber(log?.data.slice(0,((log?.data?.length/2)+1))) ){
                        // console.log('mapppppppiffifff',log?.topics[1],DecodeAddress(log?.topics?.[1]),log?.topics?.[2],DecodeAddress(log?.topics?.[2]),log.data.slice(0,((log.data?.length/2)+1)),HextoNumber(log.data.slice(0,((log.data?.length/2)+1))))
                        outputarray.push({
                            from: DecodeAddress(log?.topics?.[2])?.toLowerCase(),
                            to: DecodeAddress(log?.topics?.[3])?.toLowerCase(),
                            NFTId: HextoNumber(log.data.slice(0,((log.data?.length/2)+1))),
                            fromCertificateId: "",
                            toCertificateId: "",
                        })
                    }
                    else if( log?.data && !HextoNumber(log?.data.slice(0,((log?.data?.length/2)+1))) && DecodeAddress(log?.topics?.[2]) && HextoNumber(DecodeAddress(log?.topics?.[2])) == undefined){
                        // console.log('mapppppppiffelseifff',log?.topics?.[1],DecodeAddress(log.topics[1]),log.topics[2],DecodeAddress(log.topics[2]),log.topics[3],HextoNumber(log.topics[3]))
                        outputarray.push({
                            from: DecodeAddress(log?.topics?.[1])?.toLowerCase(),
                            to: DecodeAddress(log?.topics?.[2])?.toLowerCase(),
                            NFTId: HextoNumber(log?.topics?.[3]),
                            fromCertificateId: "",
                            toCertificateId: "",
                        })
                    }
                }
                else if(!isEmpty(outputarray?.[lastindex]) && currentNetwork.Stakecontract?.toLowerCase() == String(log?.address)?.toLowerCase()){
                    outputarray[lastindex].StakedNFTIndex = String(HextoNumber(log.data) ? (HextoNumber(log.data) - 1) : "")
                }
                else if( !isEmpty(outputarray?.[lastindex]) && ( isEmpty(outputarray?.[lastindex].fromCertificateId) || isEmpty(outputarray?.[lastindex].toCertificateId) ) ){
                    if( log?.topics?.[2] && DecodeAddress(log?.topics?.[2]) ){
                        if(isEmpty(outputarray?.[lastindex].toCertificateId)){
                            outputarray[lastindex].toCertificateId = HextoNumber(log?.topics?.[3])
                        }
                        else if(isEmpty(outputarray?.[lastindex].fromCertificateId)){
                            outputarray[lastindex].fromCertificateId = HextoNumber(log?.topics?.[3])
                        }
                    }
                }
            })
            return outputarray;
        }
        else{
            return false;
        }
    }
    catch(err){
        console.log("GetCertificateId_error",err)
        return false;
    }
}

const HextoNumber = (data) => {
    try{
        return web3?.utils?.hexToNumber(data);
    }
    catch(err){
        console.log("HextoNumbererror",err);
        return undefined;
    }
}

const DecodeAddress = (data) =>  {
    try{
        return web3.eth.abi.decodeParameter("address",data)
    }
    catch(err){
        console.log("DecodeLogerror",err);
        return 0;
    }
}

const SwitchNetwork = async(chain,toastid) => {
    try {
        if (chain) {
            let toaststatus = false;
            chain = parseInt(chain);
            let currchain = await web3.eth.getChainId();
            console.log('currchaincurrchain',!ValidChainCheck(currchain, chain))
            if (!ValidChainCheck(currchain, chain)){
                if(!toastid){
                    toastid = toast.loading("Switching Network...");
                    toaststatus = true;
                }
                let connecttype = localStorage.getItem("walletConnectType");
                let data = await connectWallet(connecttype, chain)
                console.log('dataaaaa1111', data)
                if (data?.web3) {
                    if (data?.accountAddress) {
                        if (accountAddress != data?.accountAddress) {
                            let resp = setWalletAddress("InitialConnect", data?.accountAddress, connecttype);
                            if (isEmpty(resp?.data)) {
                                toast.update(toastid, { render: "Try Again", isLoading: false, autoClose: 1000, type: "error" })
                                return false;
                            }
                        }
                        if(toaststatus){
                            toast.update(toastid, { render: "Network Switched Successfully", type: "success", isLoading: false, autoClose: 1000, isLoading: false, })
                        }
                        console.log('dispatchedddd')
                        dispatch({
                            type: "Account_Section",
                            Account_Section: {
                                AccountDetails: { ...data, cartRefresh: cartRefresh + 1 },
                            },
                        })
                        return data?.web3;
                    }
                    else {
                        toast.update(toastid, { render: (data?.display?.msg ? data?.display?.msg : "Try Again"), type: "error", autoClose: 1000, isLoading: false, });
                        return false;
                    }
                }
                else {
                    toast.update(toastid, { render: (data?.display?.msg ? data?.display?.msg : "Try Again"), type: "error", autoClose: 1000, isLoading: false, });
                    return false;
                }
            }
            else{
                return true;
                // let connecttype = localStorage.getItem("walletConnectType");
                // let data = await connectWallet(connecttype)
                // console.log('hiii',data)
                // if (data?.web3) {
                //     if (data?.accountAddress) {
                //         if (accountAddress != data?.accountAddress) {
                //             let resp = setWalletAddress("InitialConnect", data?.accountAddress, connecttype);
                //             if (isEmpty(resp?.data)) {
                //                 toast.update(toastid, { render: "Try Again", isLoading: false, autoClose: 1000, type: "error" })
                //                 return false;
                //             }
                //             dispatch({
                //                 type: "Account_Section",
                //                 Account_Section: {
                //                     AccountDetails: { ...data, cartRefresh: cartRefresh + 1 },
                //                 },
                //             })
                //             return data?.web3;
                //         }
                //         return true;
                //     }
                //     else {
                //         toast.update(toastid, { render: (data?.display?.msg ? data?.display?.msg : "Try Again"), type: "error", autoClose: 1000, isLoading: false, });
                //         return false;
                //     }
                // }
                // else {
                //     toast.update(toastid, { render: (data?.display?.msg ? data?.display?.msg : "Try Again"), type: "error", autoClose: 1000, isLoading: false, });
                //     return false;
                // }
            }
        }
        else{
            return false;
        }
    }
    catch(err){
        console.log('SwitchChainerror',err)
    }
}

    const setWalletAddress = async (type, walletAddress, walletType) => {
          var NewMethod = {
            Type: type,
            WalletAddress: walletAddress,
            WalletType: walletType,
          };
          let Resp = await userRegister(NewMethod);
          // console.log("inittttt", Resp);
          if (Resp?.success == "success") {
            dispatch({
              type: "Register_Section",
              Register_Section: {
                User: {
                  payload: Resp.data,
                  token: Resp.token ? Resp?.token : token,
                },
              },
            });
            document.cookie = "token" + "=" + Resp?.token + ";" + ";path=/";
            GetNftCookieToken();
            GetUserCookieToken();
            return Resp;
          } else return Resp;
      };

    return {
        Contract_Base_Validation,
        GetApproveStatus,
        SetApproveStatus,
        minting_721_1155,
        approve_721_1155,
        Token_Balance_Calculation,
        buy_bid_price_calculation,
        cancel_order_721_1155,
        price_calculation,
        place_order_721_1155,
        buy_721_1155,
        allowance_721_1155,
        accept_721_1155,
        GetOwner,
        GetContractOwner,
        Current_NFT_Balance,
        Transfer,
        getServiceFees,
        burn_721_1155,
        createcollection_1155,
        _signcall,
        Collection_price_calculation,
        getStackPools,
        setApproveForStack,
        getStackApproveStatus,
        nftStakingAndWithdrawAndClaim,
        batchSale,
        GetCertificateId,
        HextoNumber,
        SwitchNetwork,
        AddStackpool,

    };




}
